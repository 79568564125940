<template>
  <b-modal id="booststrapmodal" :title="T('Web.Generic.UploadFile', 'Upload file')" centered :visible="visible">
    <b-form-file v-model="file" plain accept="image/png, image/gif, image/jpeg" ></b-form-file>
    <template #modal-footer>
      <b-button
        variant="light"
        class="float-right mr-1"
        @click="handleModalHidden"
      >
        {{ T("Web.Generic.Close") }}
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        :disabled="uploading"
        @click="handleSubmit"
      >
        {{ uploading ? T("Web.Generic.Uploading", "Uploading...") : T("Web.Generic.Upload") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BFormFile, BButton } from "bootstrap-vue";
import SupplierContactsOrdersService from "../services/supplier-contacts-orders.service";
export default {
  components: {
    BModal,
    BFormFile,
    BButton,
  },
  props: {
    order: {
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      file: null,
      uploading: false,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    handleModalHidden() {
      this.file = null;
      this.visible = false;
    },
    async handleSubmit() {
      try {
        this.uploading = true;
        const service = new SupplierContactsOrdersService(
          this.order.orderSupplier.id
        );
        await service.uploadOrderFile(this.order.id, this.file);
        this.uploading = false;
        this.$emit("file-uploaded", this.order);
        this.handleModalHidden();
      } catch {
        alert("Unknown error");
      }
    },
  },
};
</script>