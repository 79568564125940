import BaseService from "@/services/base/base.service"

export default class SupplierContactsOrdersService extends BaseService {
    constructor(contactId) {
        super(`supplier-contacts/${contactId}/orders`)
    }

    async getOrders() {
        return await this.get('');
    }
    
    async getPagedOrders(page, pageSize, orderStatus)
    {
        return await this.get('paged', {page: page, perPage: pageSize, orderStatus: orderStatus})
    }

    async getOderById(id) {
        return await this.get(`${id}`);
    }

    async acceptOrderById(id) {
        return await this.post(`${id}/accept`)
    }

    async completeOrderById(id) {
        return await this.post(`${id}/complete`);
    }

    async uploadOrderFile(id, file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return await this.put(`${id}/files`, formData);
    }

    async addKgWasteCollected(id, kg) {
        return await this.put(`${id}/kg-collected`, { kgWasteCollected: kg });
    }
}