import ordering from "@/router/routes/ordering";
import BaseService from "@/services/base/base.service"
import { uuid } from "vue-uuid";

export default class SupplierOrdersService extends BaseService {
    constructor() {
        super(`suppliers/orders`)
    }

    async getOrders() {
        return await this.get('');
    }

    async getPagedOrders(page, pageSize, orderStatus)
    {
        return await this.get('paged', {page: page, perPage: pageSize, orderStatus: orderStatus})
    }

    async getOderById(id) {
        return await this.get(`${id}`);
    }

    async acceptOrderById(id) {
        return await this.post(`${id}/accept`)
    }

    async completeOrderById(id) {
        return await this.post(`${id}/complete`);
    }

    async forwardOrder(id, userId) {
        return await this.post(`${id}/forward`, { id: id, forwardedUserId: userId });
    }

    async removeForward(id) {
        return await this.post(`${id}/forward-remove`);
    }

    async uploadOrderFile(id, file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return await this.put(`${id}/files`, formData);
    }

    async addKgWasteCollected(id, kg)
    {
        return await this.put(`${id}/kg-collected`, {kgWasteCollected: kg});
    }

    async addOrderForwardRule(infocardId, userId)
    {
        return await this.post("forward-rules", {id: uuid.v4(), infocardId: infocardId, userId: userId});
    }
}