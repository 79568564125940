<template>
  <b-modal
    v-if="order != null"
    id="booststrapmodal"
    :title="`${T('Web.Generic.Ordering.OrderPopup.title', 'Order')} ${
      order.requisitionId
    }`"
    centered
    :visible="visible"
    size="lg"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
  >
    <b-overlay :show="showLoader">
      <add-order-file-modal
        ref="AddOrderFileModal"
        :order="order"
        @file-uploaded="(e) => $emit('file-uploaded', e)"
      />
      <confirm-order-forward-rule-modal ref="confirmModal" />
      <b-row class="order-row" ref="PrintArea" v-if="order != null">
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Ordering.RequisitionId") }}
          </span>
        </b-col>
        <b-col cols="12" md="8"> {{ order.requisitionId }} </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Ordering.OrderingDate") }}
          </span>
        </b-col>
        <b-col cols="12" md="8"> {{ format_datetime(order.orderDate) }} </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Ordering.OrderStatus", "Status") }}
          </span>
        </b-col>
        <b-col cols="12" md="8"
          ><b-badge variant="danger" v-if="order.status == 'Canceled'">{{
            T("Web.Generic.Ordering.Status.Canceled")
          }}</b-badge>
          <b-badge variant="secondary" v-else-if="order.status == 'Created'">{{
            T("Web.Generic.Ordering.Status.Created")
          }}</b-badge>
          <b-badge variant="primary" v-else>{{
            T(`Web.Generic.Ordering.Status.${order.status}`)
          }}</b-badge>
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.Supplier")
            }}</span
          >
        </b-col>
        <b-col
          cols="12"
          md="8"
          class="d-flex justify-content-between align-items-center"
        >
          <span>{{ order.orderSupplier.name }}</span>
          <span v-if="orderIsForwarded(order)" class="text-primary">
            <feather-icon icon="CornerUpRightIcon" />
            {{
              T("Web.Generic.Ordering.ForwardedTo")
            }}
            <span class="font-weight-bold">
              {{ getForwardReciverName(order) }}</span
            >
            <br />
            <b-button
              v-if="isSupplier && !isForwardedToSupplier(order)"
              variant="danger"
              size="sm"
              class="float-right"
              @click="$emit('forwarde-removed', order)"
              >{{
                T(
                  "Web.Generic.Ordering.RemoveForward"
                )
              }}</b-button
            >
          </span>
          <template
            v-else-if="
              forwardOptions.length > 0 &&
              isSupplier &&
              order.status == 'Created'
            "
          >
            <b-dropdown size="sm" no-caret>
              <template #button-content>
                <span class="mr-1">{{
                  T("Web.Generic.Ordering.Forward")
                }}</span>
                <feather-icon icon="CornerUpRightIcon" />
              </template>
              <b-dropdown-item
                href="#"
                v-for="user in forwardOptions"
                :key="user.id"
                @click="handleForwardClicked(user)"
                >{{ user.alias }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-col>
        <b-col cols="12"> <hr /> </b-col>

        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.Orderer")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          {{ order.orderer.concernName }} {{order.orderSupplier.customerNumberAtSupplier != null && order.orderSupplier.customerNumberAtSupplier != "" ? `(${order.orderSupplier.customerNumberAtSupplier})` : ""}} <br />
          <small
            ><a :href="`mailto:${order.orderer.email}`"
              >{{ order.orderer.name }}
            </a></small
          >
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Locations.Location")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8"> {{ order.pickupLocation.name }} </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Address")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          {{ order.pickupLocation.zip }} {{ order.pickupLocation.city }},
          {{ order.pickupLocation.address }}
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold"> Modtageranlæg</span>
        </b-col>
        <b-col cols="12" md="8">
          <template v-if="order.recivingfacility">
            {{ order.recivingfacility.name }} <br />{{
              order.recivingfacility.zip
            }}
            {{ order.recivingfacility.city }},
            {{ order.recivingfacility.address }}
          </template>
        </b-col>
        <b-col cols="12"> <hr /> </b-col>

        <b-col cols="12" md="4">
          <span class="font-weight-bold"
            >{{
              T("Web.Generic.Ordering.ContainerId")
            }}
          </span>
        </b-col>

        <b-col cols="12" md="8">
          {{ order.orderItem.refId }} / {{ order.orderItem.receiverRefId }}
          <br />
          <small>
            {{ order.orderItem.type }}
            <span v-if="order.orderItem.size > 0"
              >{{ order.orderItem.size }} {{ order.orderItem.sizeUnit }}</span
            >
          </small>
        </b-col>

        <b-col cols="12" md="4">
          <span class="font-weight-bold"
            >{{
              T("Web.Generic.Quantity")
            }}
          </span>
        </b-col>
        <b-col cols="12" md="8">
         {{order.quantity}}
        </b-col>

        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.WasteFraction", "Waste fraction")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          {{
            order.orderItem.wasteFraction
              ? order.orderItem.wasteFraction.name
              : ""
          }}
          <br />
          <small>{{
            order.orderItem.ewcCode ? order.orderItem.ewcCode.name : ""
          }}</small>
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.WantsEmptied")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          {{ T("Web.Generic.Ordering.AsFastAsPossible") }}
          <!--
        <span v-if="order.orderItem.pickupDays.length == 0">Hurtigts muligt</span>
        <weekday-picker :disabled="true" v-model="order.orderItem.pickupDays" />
        -->
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.Comment")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8" :class="{ 'bg-warning': order.comment }">
          {{ order.comment }}</b-col
        >
        <template v-if="order.status == 'Accepted'">
          <b-col cols="12" md="4">
            <span class="font-weight-bold">{{
              T(
                "Web.Generic.Ordering.KgWasteCollected"
              )
            }}</span>
          </b-col>
          <b-col cols="12" md="8">
            <b-form-input
              type="number"
              v-model="order.kgWasteCollected"
              @blur="$emit('kg-waste-collected-added', order)"
            />
          </b-col>
        </template>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.AttachedFiles")
            }}</span
          >
        </b-col>

        <b-col cols="12" md="8">
          <span v-if="order.files.lengt == 0">{{
            T("Web.Generic.Ordering.NoFilesAttached")
          }}</span>
          <div v-else>
            <div class="img-list">
              <div v-for="file in order.files" :key="file.id">
                <a
                  :href="apiBaseUrl() + '/images/' + file.path"
                  target="_blank"
                >
                  <img :src="apiBaseUrl() + '/images/' + file.path" />
                </a>
              </div>
            </div>
          </div>
          <b-button
            variant="light"
            size="sm"
            class="w-100"
            @click="openAddOrderFileModal"
            >{{ T("Web.Generic.Ordering.AddFile", "Add file") }}
            <feather-icon icon="PlusIcon"
          /></b-button>
        </b-col>
      </b-row>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100" v-if="order != null">
        <div class="w-100">
          <b-button variant="light" class="float-left print-btn" @click="print">
            {{
              T("Web.Generic.Ordering.PrintOrder", "Print order")
            }}
            <feather-icon icon="PrinterIcon" />
          </b-button>

          <b-button
            v-if="
              order.status == 'Created' &&
              (!isSupplier || !orderIsForwarded(order))
            "
            variant="primary"
            class="float-right ml-1"
            @click="$emit('accepted', order)"
          >
            {{ T("Web.Generic.Ordering.AcceptOrder") }}
          </b-button>
          <b-button
            v-if="
              order.status == 'Accepted' &&
              (!isSupplier || !orderIsForwarded(order))
            "
            variant="primary"
            class="float-right ml-1"
            @click="$emit('completed', order)"
          >
            {{
              T(
                "Web.Generic.Ordering.MarkAsCollected"
              )
            }}
          </b-button>
          <b-button
            variant="light"
            class="float-right"
            @click="$emit('cancel')"
          >
            {{ T("Web.Generic.Close") }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import authHelper from "@/auth";
import {
  BModal,
  BRow,
  BCol,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BOverlay,
} from "bootstrap-vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
import AddOrderFileModal from "./AddOrderFileModal.vue";
import ConfirmOrderForwardRuleModal from "./ConfirmOrderForwardRuleModal.vue";
export default {
  props: {
    visible: {
      default: false,
    },
    order: {
      required: true,
    },
    showLoader: {
      required: false,
      default: false,
    },
    forwardOptions: {
      required: false,
      default() {
        return [];
      },
    },
  },
  components: {
    BModal,
    BRow,
    BCol,
    BBadge,
    BButton,
    WeekdayPicker,
    AddOrderFileModal,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BOverlay,
    ConfirmOrderForwardRuleModal,
  },
  methods: {
    orderIsForwarded(order) {
      const supplierScopeId = authHelper.getAccountId();
      return (
        this.isSupplier &&
        (order.forwardedUser != null ||
          (order.forwardedSupplier != null &&
            order.forwardedSupplier.id != supplierScopeId))
      );
    },
    getForwardReciverName(order) {
      const supplierScopeId = authHelper.getAccountId();
      if (
        order.forwardedSupplier == null ||
        order.forwardedSupplier.id == supplierScopeId
      )
        return order.forwardedUser.name;
      return order.forwardedSupplier.name;
    },
    isForwardedToSupplier(order) {
      const supplierScopeId = authHelper.getAccountId();
      return !(
        order.forwardedSupplier == null ||
        order.forwardedSupplier.id == supplierScopeId
      );
    },
    print() {
      var prtContent = this.$refs.PrintArea;
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    openAddOrderFileModal() {
      this.$refs.AddOrderFileModal.open();
    },
    async handleForwardClicked(user) {
      const makeRule = false;
      //const makeRule = await this.$refs.confirmModal.open();
      this.$emit("forwarded", {
        user: user,
        order: this.order,
        createRule: makeRule,
      });
    },
  },
  computed: {
    isSupplier() {
      return (
        authHelper.getRole() == "Supplier" ||
        authHelper.getRole() == "SuperAdmin"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.order-row {
  font-size: 15px;

  div {
    margin-bottom: 5px;
  }

  .img-list {
    display: flex;
    max-width: 800px;
    flex-wrap: wrap;
    span.icon-container {
      position: absolute;
      top: 2px;
      right: 2px;
      span {
        &.hover-effect:hover {
          background-color: #cf4330;
        }
      }
    }
    div {
      position: relative;
      width: 180px;
      margin: 3px 2px;
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
}
@media (max-width: 767px) {
  .print-btn {
    display: none;
  }
}
</style>