<template>
  <b-modal
    id="booststrapmodal"
    :title="T('Web.Generic.Ordering.AddForwardRule', 'Add forward rule')"
    centered
    :visible="visible"
    :hide-header-close="true"
    class="text-align-center"
  >
    <h3>
      Ønsker du at alle fremtidige bestillinger på dette infokort automatisk
      skal vidersendes til denne bruger?
    </h3>
    <template #modal-footer>
      <b-button variant="primary" class="float-right" @click="handleAccept">
        {{ $t("Generic.Confimration.Yes") }}
      </b-button>
      <b-button variant="light" class="float-right" @click="handleReject">
        {{ $t("Generic.Confimration.No") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BButton,
  },
  data() {
    return {
      visible: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    open() {
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    created()
    {
      console.log("here");
    },
    handleModalHidden() {
      this.visible = false;
    },
    handleAccept() {
      this.resolvePromise(true);
      this.handleModalHidden();
    },
    handleReject() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
};
</script>