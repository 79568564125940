<template>
  <div class="order-inbox">
    <div class="side-bar">
      <div class="side-bar-menu">
        <div class="filter-con">
          <b-dropdown
            :text="T('Web.Generic.Filters')"
            size="sm"
            variant="flat-secondary"
          >
            <template #button-content>
              <feather-icon icon="FilterIcon" /> {{ T("Web.Generic.Filters") }}
            </template>
            <b-dropdown-form class="inner">
              <div class="option-header">
                <h1>{{ T("Web.Generic.Concern") }}</h1>
                <a @click.prevent="concernsFilter = []" href="#">{{
                  T("Web.Generic.ClearAll", "Clear all")
                }}</a>
              </div>
              <b-form-checkbox-group
                id="checkbox-group-concerns"
                v-model="concernsFilter"
              >
                <b-form-checkbox
                  class="w-100"
                  v-for="(concern, index) in concerns"
                  :key="index"
                  :value="concern"
                  >{{ concern }}</b-form-checkbox
                >
              </b-form-checkbox-group>
              <hr />
              <div class="option-header">
                <h1>{{ T("Web.Generic.Locations.Locations") }}</h1>
                <a @click.prevent="locationsFilter = []" href="#">{{
                  T("Web.Generic.ClearAll", "Clear all")
                }}</a>
              </div>
              <b-form-checkbox-group
                id="checkbox-group-locations"
                v-model="locationsFilter"
              >
                <b-form-checkbox
                  class="w-100"
                  v-for="(location, index) in locations"
                  :key="index"
                  :value="location"
                  >{{ location }}</b-form-checkbox
                >
              </b-form-checkbox-group>
              <hr />
              <div class="option-header">
                <h1>
                  {{
                    T(
                      "Web.Generic.Ordering.ReceivingFacilities",
                      "Receiving facilities"
                    )
                  }}:
                </h1>
                <a @click.prevent="recivingfacilitiesFilter = []" href="#">{{
                  T("Web.Generic.ClearAll", "Clear all")
                }}</a>
              </div>
              <b-form-checkbox-group
                id="checkbox-group-locations"
                v-model="recivingfacilitiesFilter"
              >
                <b-form-checkbox
                  class="w-100"
                  v-for="(recivingfacility, index) in recivingfacilities"
                  :key="index"
                  :value="recivingfacility"
                  >{{ recivingfacility }}</b-form-checkbox
                >
              </b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
        </div>
        <b-list-group class="list-group-messages">
          <b-list-group-item
            :class="{ active: activeOrderCategory == 'INBOX' }"
            @click="$emit('category-clicked', 'INBOX')"
          >
            <feather-icon icon="InboxIcon" size="18" class="mr-75" />
            <span class="align-text-bottom line-height-1">{{
              T("Web.SupplierOdersPage.OrderInbox.Inbox", "Inbox")
            }}</span>
            <b-badge
              v-if="
                totalOrdersInCategory >= 0 && activeOrderCategory == 'INBOX'
              "
              pill
              class="float-right"
              variant="light-primary"
            >
              {{ totalOrdersInCategory }}
            </b-badge>
          </b-list-group-item>
          <b-list-group-item
            :class="{ active: activeOrderCategory == 'CONFIRMED' }"
            @click="$emit('category-clicked', 'CONFIRMED')"
          >
            <feather-icon icon="CheckSquareIcon" size="18" class="mr-75" />
            <span class="align-text-bottom line-height-1">{{
              T("Web.SupplierOdersPage.OrderInbox.AcceptedOrders", "Accpeted")
            }}</span>
            <b-badge
              v-if="
                totalOrdersInCategory >= 0 && activeOrderCategory == 'CONFIRMED'
              "
              pill
              class="float-right"
              variant="light-primary"
            >
              {{ totalOrdersInCategory }}
            </b-badge>
          </b-list-group-item>
          <b-list-group-item
            :class="{ active: activeOrderCategory == 'CLOSED' }"
            @click="$emit('category-clicked', 'CLOSED')"
          >
            <feather-icon icon="ClipboardIcon" size="18" class="mr-75" />
            <span class="align-text-bottom line-height-1">{{
              T("Web.SupplierOdersPage.OrderInbox.CollectedOrders", "Collected")
            }}</span>
            <b-badge
              v-if="
                totalOrdersInCategory >= 0 && activeOrderCategory == 'CLOSED'
              "
              pill
              class="float-right"
              variant="light-primary"
            >
              {{ totalOrdersInCategory }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <vue-perfect-scrollbar
      :settings="{
        maxScrollbarLength: 150,
      }"
      class="orders-list"
    >
      <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
      </div>
      <h3
        class="text-muted d-flex justify-content-center mt-5"
        v-else-if="filteredOrdersToShow.length == 0"
      >
        {{
          T(
            "Web.SupplierOdersPage.OrderInbox.NoOrdersToShow",
            "No orders to show"
          )
        }}
      </h3>
      <ul class="orders-ul">
        <li
          v-for="order in filteredOrdersToShow"
          :key="order.id"
          :class="{ 'bg-light-primary': orderIsForwarded(order) }"
        >
          <div class="right">
            <div class="order-details">
              <div>
                <h1 class="head-title">
                  {{ order.orderer.concernName }}
                  {{
                    order.orderSupplier.customerNumberAtSupplier != null &&
                    order.orderSupplier.customerNumberAtSupplier != ""
                      ? `(${order.orderSupplier.customerNumberAtSupplier})`
                      : ""
                  }}
                  <br />
                  <small>
                    <span v-if="orderIsForwarded(order)" class="text-primary">
                      <feather-icon icon="CornerUpRightIcon" />
                      {{
                        T("Web.Generic.Ordering.ForwardedTo", "Forwarded to")
                      }}

                      <span class="font-weight-bold">
                        {{ getForwardReciverName(order) }}</span
                      >
                    </span>
                  </small>
                </h1>
                <span class="d-flex">
                  <feather-icon icon="MapIcon" class="mr-1" />
                  <h5 class="font-weight-bold">
                    {{ order.pickupLocation.zip }}
                    {{ order.pickupLocation.city }},
                    {{ order.pickupLocation.address }}
                  </h5>
                  <feather-icon
                    icon="ArrowRightIcon"
                    style="margin-right: 5px; margin-left: 5px"
                  />
                  <h5 class="font-weight-bold">
                    {{
                      order.recivingfacility != null
                        ? order.recivingfacility.name
                        : "--"
                    }}
                  </h5>
                </span>
                <span class="d-flex">
                  <feather-icon icon="BoxIcon" class="mr-1" />
                  <h5 class="font-weight-bold">
                    {{ order.orderItem.name }}
                    <small
                      >({{ order.orderItem.refId }} /
                      {{ order.orderItem.receiverRefId }})</small
                    >
                    <br />
                    <small>
                      {{
                        order.orderItem.wasteFraction
                          ? order.orderItem.wasteFraction.name
                          : ""
                      }}<span v-if="order.orderItem.type">,</span>
                      {{ order.orderItem.type }}
                      <span v-if="order.orderItem.size > 0">
                        {{ order.orderItem.size }}
                        {{
                          T(`Web.Generic.Units.${order.orderItem.sizeUnit}`)
                        }}</span
                      ></small
                    >
                    <br />
                    <small
                      >{{
                        order.orderItem.ewcCode
                          ? order.orderItem.ewcCode.name
                          : ""
                      }}
                    </small>
                  </h5>
                </span>
                <span class="d-flex" v-if="order.comment">
                  <feather-icon icon="MessageCircleIcon" class="mr-1" />
                  <h5 class="font-weight-bold">
                    <small class="bg-warning">{{ order.comment }}</small>
                  </h5>
                </span>
                <span class="d-flex" v-if="order.containerRef">
                  <feather-icon icon="ClipboardIcon" class="mr-1" />
                  <h5 class="font-weight-bold">
                    <small>{{ order.containerRef }}</small>
                  </h5>
                </span>
              </div>
              <div
                class="d-flex flex-column align-items-end justify-content-between"
              >
                <div class="order-date">
                  {{ format_datetime(order.orderDate) }}
                  <br />
                  <small
                    ><span class="font-weight-bold">
                      {{ T("Web.Generic.Ordering.RequisitionId") }}
                    </span>
                    {{ order.requisitionId }}</small
                  >
                </div>
                <div>
                  <template v-if="isSupplier">
                    <b-button
                      class="mr-1"
                      v-if="
                        orderIsForwarded(order) && !isForwardedToSupplier(order)
                      "
                      variant="danger"
                      size="sm"
                      @click="$emit('forwarde-removed', order)"
                      >{{
                        T(
                          "Web.Generic.Ordering.RemoveForward",
                          "Remove forwardning"
                        )
                      }}</b-button
                    >
                    <template
                      v-if="
                        !orderIsForwarded(order) &&
                        forwardOptions.length > 0 &&
                        isSupplier &&
                        order.status == 'Created'
                      "
                    >
                      <b-dropdown size="sm" class="mr-1" no-caret>
                        <template #button-content>
                          <span class="mr-1">{{
                            T("Web.Generic.Ordering.Forward", "Forward")
                          }}</span>
                          <feather-icon icon="CornerUpRightIcon" />
                        </template>
                        <b-dropdown-item
                          href="#"
                          v-for="user in forwardOptions"
                          :key="user.id"
                          @click="handleForwardClicked(user, order)"
                          >{{ user.alias }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </template>
                  <b-button
                    v-if="
                      order.status == 'Accepted' &&
                      (!isSupplier || !orderIsForwarded(order))
                    "
                    variant="primary"
                    class="mr-1"
                    size="sm"
                    @click="$emit('completed', order)"
                  >
                    {{
                      T(
                        "Web.Generic.Ordering.MarkAsCollected",
                        "Mark as collected"
                      )
                    }}
                  </b-button>
                  <b-button
                    class="mr-1"
                    size="sm"
                    v-if="
                      order.status == 'Created' &&
                      (!isSupplier || !orderIsForwarded(order))
                    "
                    variant="primary"
                    @click="$emit('accepted', order)"
                  >
                    {{ T("Web.Generic.Ordering.AcceptOrder", "Accept") }}
                  </b-button>
                  <b-button size="sm" @click="$emit('show-order', order)">
                    {{
                      T("Web.Generic.Ordering.ShowOrderDetails", "Show details")
                    }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="fetch-more-li"
          v-if="canFetchMoreItems || isFetchingItems"
          @click="$emit('fetch-more-requested')"
        >
          <span v-if="isFetchingItems">{{ T("Web.Generic.Loading") }}...</span>
          <a href="#" v-else
            >{{ T("Web.Generic.FetchMore") }} <feather-icon icon="RotateCwIcon"
          /></a>
        </li>
      </ul>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import authHelper from "@/auth";
import {
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
  BDropdownHeader,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import SupplierOrdersService from "../services/supplier-orders.service";

export default {
  props: {
    isFetchingItems: {
      required: false,
      default: false,
    },
    isLoading: {
      required: true,
    },
    orders: {
      required: false,
      default() {
        return [];
      },
    },
    activeOrderCategory: {
      required: true,
    },
    totalOrdersInCategory: {
      required: false,
      default: -1,
    },
    canFetchMoreItems: {
      required: false,
      default: false,
    },

    forwardOptions: {
      required: false,
      default() {
        return [];
      },
    },
    overwriteOrders: {
      required: false,
      default() {
        return [];
      },
    },
  },
  components: {
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BDropdownHeader,
    BFormCheckbox,
    BFormCheckboxGroup,
    FeatherIcon,
  },
  data() {
    return {
      concernsFilter: [],
      locationsFilter: [],
      recivingfacilitiesFilter: [],
    };
  },
  created() {
    this.resetFilters();
  },
  computed: {
    newOrders() {
      return this.orders.filter((o) => o.status == "Created");
    },
    confirmedOrders() {
      return this.orders.filter((o) => o.status == "Accepted");
    },
    closedOrders() {
      return this.orders.filter((o) => o.status == "Collected");
    },
    ordersToShow() {
      if (this.activeOrderCategory == "INBOX") return this.newOrders;
      if (this.activeOrderCategory == "CONFIRMED") return this.confirmedOrders;
      if (this.activeOrderCategory == "CLOSED") return this.closedOrders;
      return [];
    },
    filteredOrdersToShow() {
      const self = this;
      let list = this.ordersToShow.filter(
        (o) =>
          self.concernsFilter.includes(o.orderer.concernName) &&
          self.locationsFilter.includes(o.pickupLocation.name)
      );

      if (
        this.recivingfacilitiesFilter.length != this.recivingfacilities.length
      ) {
        list = list.filter(
          (o) =>
            o.recivingfacility != null &&
            this.recivingfacilitiesFilter.includes(o.recivingfacility.name)
        );
      }

      return list;
    },
    isSupplier() {
      return (
        authHelper.getRole() == "Supplier" ||
        authHelper.getRole() == "SuperAdmin"
      );
    },
    concerns() {
      const uniqueOrderers = [];

      this.ordersToShow.forEach((order) => {
        const ordererConcernName = order.orderer?.concernName;
        const ordererAlreadyAdded = uniqueOrderers.some(
          (uniqueOrderer) => uniqueOrderer === ordererConcernName
        );

        if (!ordererAlreadyAdded) {
          uniqueOrderers.push(ordererConcernName);
        }
      });

      return uniqueOrderers;
    },
    locations() {
      const uniqueLocations = new Set();
      this.ordersToShow.forEach((order) => {
        const locationName = order.pickupLocation?.name;
        uniqueLocations.add(locationName);
      });
      return Array.from(uniqueLocations);
    },
    recivingfacilities() {
      const uniqueList = new Set();
      this.ordersToShow.forEach((order) => {
        if (order.recivingfacility != null) {
          const facilityName = order.recivingfacility.name;
          uniqueList.add(facilityName);
        }
      });
      return Array.from(uniqueList);
    },
  },
  methods: {
    async fetchInbox() {
      const service = new SupplierOrdersService();
      var inboxResult = await service.getPagedOrders(1, 10000, true);
      this.inboxItems = inboxResult.data.data.items;
      this.inboxSize = inboxResult.data.data.paging.totalItems;
    },

    orderIsForwarded(order) {
      const supplierScopeId = authHelper.getAccountId();
      return (
        this.isSupplier &&
        (order.forwardedUser != null ||
          (order.forwardedSupplier != null &&
            order.forwardedSupplier.id != supplierScopeId))
      );
    },
    getForwardReciverName(order) {
      const supplierScopeId = authHelper.getAccountId();
      if (
        order.forwardedSupplier == null ||
        order.forwardedSupplier.id == supplierScopeId
      )
        return order.forwardedUser.name;
      return order.forwardedSupplier.name;
    },
    isForwardedToSupplier(order) {
      const supplierScopeId = authHelper.getAccountId();
      return !(
        order.forwardedSupplier == null ||
        order.forwardedSupplier.id == supplierScopeId
      );
    },
    resetFilters() {
      this.concernsFilter = this.concerns;
      this.locationsFilter = this.locations;
      this.recivingfacilitiesFilter = this.recivingfacilities;
    },
    async handleForwardClicked(user, order) {
      //const makeRule = await this.$refs.confirmModal.open();
      const makeRule = false;
      this.$emit("forwarded", {
        user: user,
        order: order,
        createRule: makeRule,
      });
    },
  },
  watch: {
    isLoading(newValue) {
      if (newValue == false) {
        this.resetFilters();
      }
    },
    isFetchingItems(newValue) {
      if (newValue == false) {
        this.resetFilters();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.option-header {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
  }
}
.filter-con {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ebe9f1;
}
.head-title {
  font-size: 15px;
  font-weight: bold;
}
.order-inbox {
  background: #fff;
  border: 1px solid #ebe9f1;
  border-radius: 0.428rem;
  height: calc(100vh - 120px);
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;

  .side-bar {
    width: 260px;
    height: 100%;
    border-right: 1px solid #ebe9f1;
    .title {
      padding: 1.5rem;
      text-align: center;
      border-bottom: 1px solid #ebe9f1;
    }

    .list-group {
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      border-radius: 0.357rem;

      .list-group-item {
        padding: 0.58rem 1.5rem;
        border: 0;
        letter-spacing: 0.4px;
        border-left: 2px solid transparent;
        border-radius: 0;

        &.active {
          background: 0 0;
          color: #407e3d;
          border-color: #407e3d;
          font-weight: 500;
        }

        &:hover {
          color: #407e3d;
          cursor: pointer;
        }
      }
    }
  }

  .orders-list {
    width: calc(100% - 260px);

    @media only screen and (max-width: 648px) {
      width: 100%;
    }

    ul.orders-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        background-color: #f8f8f8;
        padding: 1.5rem;
        border-bottom: 1px solid #e7e3ed;
        display: flex;
        .left {
          width: 50px;
          position: relative;
          .info-icon {
            background-color: #407e3d;
            border-radius: 50%;
            color: #fff;
            position: absolute;
            bottom: 5px;
            right: 5px;
            cursor: pointer;
          }
        }

        .right {
          display: flex;
          width: 100%;
          .order-details {
            width: 100%;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;

            h1 {
              margin-bottom: 0px;
              padding-bottom: 5px;
            }

            @media (max-width: 767px) {
              h1 {
                font-size: 15px;
              }
              h5 {
                font-size: 12px;
              }
            }

            .order-date {
              color: #b9b9c3;
              font-size: 0.857rem;
            }

            .details-text {
              font-size: 11px;
              display: block;
              margin-bottom: 2px;
            }

            .actions {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                margin-right: 5px;
              }
            }
          }
        }
      }

      > li.fetch-more-li {
        font-weight: bold;
        display: flex;
        justify-content: center;
        background: #e7e3ed;

        &:hover {
          background: #f8f8f8;
        }
      }
    }
  }

  @media only screen and (max-width: 648px) {
    flex-direction: column;

    .side-bar {
      border-right: 0px;
      width: 100%;
      height: 165px;
      border-bottom: 1px solid #ebe9f1;

      .title {
        padding: 10px;
        font-size: 20px;
      }
    }
  }
}

.loader-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .loader {
    height: 10px;
    width: 130px;
    --c: no-repeat linear-gradient(#407e3d 0 0);
    background: var(--c), var(--c), rgb(64 126 61 / 12%);
    background-size: 60% 100%;
    animation: l16 3s infinite;
  }
  @keyframes l16 {
    0% {
      background-position: -150% 0, -150% 0;
    }
    66% {
      background-position: 250% 0, -150% 0;
    }
    100% {
      background-position: 250% 0, 250% 0;
    }
  }
}
</style>